import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Spinner, Table } from 'react-bootstrap';
import AppLayout from '../../../layouts/AppLayout/AppLayout';
import { Helmet } from 'react-helmet';

function KeywordResearchTool() {
  const [seedKeyword, setSeedKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!seedKeyword.trim()) return;
    
    setLoading(true);
    setResults([]);

    try {
      const response = await fetch(
        `https://api.datamuse.com/words?ml=${encodeURIComponent(seedKeyword)}&max=20`
      );
      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error("Error fetching keyword data:", error);
    }
    setLoading(false);
  };

  return (
    <AppLayout variant={7} title="Keyword Research Tool">
      <Helmet>
        <title>Keyword Research Tool | Uncover Valuable Keywords for CRO &amp; SEO</title>
        <meta
          name="description"
          content="Discover related keywords using our free Keyword Research Tool. Identify valuable keywords to drive organic traffic, boost conversions, and improve SEO through effective CRO strategies."
        />
        <link rel="canonical" href="https://www.siteoptimizr.com/keyword-research-tool" />
        <meta property="og:title" content="Keyword Research Tool | Uncover Valuable Keywords for CRO &amp; SEO" />
        <meta
          property="og:description"
          content="Discover related keywords using our free Keyword Research Tool. Find valuable keywords that can drive organic traffic and boost conversions through effective CRO strategies."
        />
        <meta property="og:url" content="https://www.siteoptimizr.com/keyword-research-tool" />
        <meta property="og:type" content="website" />
      </Helmet>

      <main>
        <Container className="py-5 mt-9">
          <Row className="justify-content-center">
            <Col md={10}>
              {/* Input Form Card */}
              <Card className="mb-4 shadow-sm border-0">
                <Card.Body className="p-5">
                  <h1 className="text-center mb-3 text-primary">Keyword Research Tool</h1>
                  <p className="text-center text-muted mb-4">
                    Enter a seed keyword to discover related keywords that can drive organic traffic.
                    Effective Conversion Rate Optimization (CRO) helps you uncover valuable keywords—and that, in turn, benefits your SEO.
                  </p>
                  <Form onSubmit={handleSearch}>
                    <Form.Group controlId="seedKeyword" className="mb-3">
                      <Form.Label>Seed Keyword</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="e.g., conversion optimization"
                        value={seedKeyword}
                        onChange={(e) => setSeedKeyword(e.target.value)}
                      />
                      <Form.Text className="text-muted">
                        Enter a keyword related to your industry or service.
                      </Form.Text>
                    </Form.Group>
                    <div className="d-grid">
                      <Button variant="primary" type="submit" size="lg">
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{' '}
                            Searching...
                          </>
                        ) : (
                          'Search Keywords'
                        )}
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>

              {/* Results Display */}
              {results.length > 0 && (
                <Card className="shadow-sm border-0 mb-4">
                  <Card.Body className="p-5">
                    <h2 className="text-center text-success mb-4">Keyword Research Results</h2>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Keyword</th>
                          <th>Relevance Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {results.map((item, index) => (
                          <tr key={index}>
                            <td>{item.word}</td>
                            <td>{item.score}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              )}

              <Card className="shadow-sm border-0">
                <Card.Body className="p-5">
                  <h2 className="text-center mb-3 text-primary">
                    Supercharge Your Conversions with Our CRO Services!
                  </h2>
                  <p className="text-center text-muted mb-4">
                    Our expert Conversion Rate Optimization (CRO) services help you uncover valuable keywords and drive organic traffic.
                    Optimize your landing pages for higher conversions while enjoying the SEO benefits that come with a better user experience.
                  </p>
                  <div className="d-grid">
                    <Button variant="blue" size="lg" href="/contact-us">
                      Get Your Free CRO Consultation
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </AppLayout>
  );
}

export default KeywordResearchTool;