import React from 'react'
import { Col, Row } from 'react-bootstrap'

// layout
import AppLayout from '../../layouts/AppLayout/AppLayout';

// components 
import { NioButton, NioCard, NioSection, NioMedia, NioSubscribeField } from '../../components';

function index() {
  return (
    <AppLayout title="404" rootClass="layout-1">
      <NioSection className="nk-section-error pt-120 pt-xl-180 overflow-hidden pb-0" masks={["blur-1 left top", "blur-1 right bottom"]}>
        <Row className="justify-content-center text-center">
          <Col lg={6} xl={8}>
            <div className="pb-5 pb-lg-7">
              <div className="error-number mb-5">404</div>
              <h2 className="m-0 mb-2 mb-md-4" >Oops, Page Not Found.</h2>
             
              <ul className="nk-btn-group pt-4 justify-content-center pt-3 pt-md-5 pb-2">
                <li>
                  <NioButton href="/" className="btn-primary" label="Go Back Home" />
                </li>
             
             
              </ul>
              <hr className="mt-md-6" />
            </div>
          </Col>
        </Row>
       
      </NioSection>

     

    </AppLayout>
  )
}

export default index