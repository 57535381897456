import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, ProgressBar } from 'react-bootstrap';
import AppLayout from '../../../layouts/AppLayout/AppLayout';
import { Helmet } from 'react-helmet';

function ConversionCalculator() {
  const [visitors, setVisitors] = useState('');
  const [conversionRate, setConversionRate] = useState('');
  const [avgOrderValue, setAvgOrderValue] = useState('');
  const [results, setResults] = useState(null);

  const handleCalculate = (e) => {
    e.preventDefault();

    const visitorsNum = parseFloat(visitors);
    const conversionRateNum = parseFloat(conversionRate);
    const avgOrderValueNum = parseFloat(avgOrderValue);

    if (isNaN(visitorsNum) || isNaN(conversionRateNum)) {
      alert('Please enter valid numbers for visitors and conversion rate.');
      return;
    }

    // Baseline conversions
    const baselineConversions = visitorsNum * (conversionRateNum / 100);
    // Improvement scenarios (relative increase percentages)
    const improvements = [20, 30, 40, 50];

    const newResults = improvements.map((improvement) => {
      // New conversion rate after applying the improvement
      const newConversionRate = conversionRateNum * (1 + improvement / 100);
      const newConversions = visitorsNum * (newConversionRate / 100);
      const additionalConversions = newConversions - baselineConversions;
      const additionalRevenue = !isNaN(avgOrderValueNum)
        ? additionalConversions * avgOrderValueNum
        : null;

      return {
        improvement,
        newConversionRate: newConversionRate.toFixed(2),
        baselineConversions: Math.floor(baselineConversions),
        newConversions: Math.floor(newConversions),
        additionalConversions: Math.floor(additionalConversions),
        additionalRevenue: additionalRevenue !== null ? additionalRevenue.toFixed(2) : null,
      };
    });

    setResults(newResults);
  };

  return (
    <AppLayout variant={7} title="Conversion Calculator">
      <Helmet>
        <title>Conversion Revenue Calculator | Boost Your Sales</title>
        <meta
          name="description"
          content="Unlock your revenue potential with our Conversion Revenue Calculator. Discover how a small increase in your conversion rate can drive significant sales and revenue growth for your business."
        />
        <link rel="canonical" href="https://www.siteoptimizr.com/conversion-calculator" />
        <meta property="og:title" content="Conversion Revenue Calculator | Boost Your Sales" />
        <meta
          property="og:description"
          content="Unlock your revenue potential with our Conversion Revenue Calculator. Discover how a small increase in your conversion rate can drive significant sales and revenue growth for your business."
        />
        <meta property="og:url" content="https://www.siteoptimizr.com/conversion-calculator" />
        <meta property="og:type" content="website" />
      </Helmet>

      <main>
        <Container className="py-5 mt-9">
          <Row className="justify-content-center">
            <Col md={8}>
              {/* Calculator Input Card */}
              <Card className="mb-4 shadow-sm border-0">
                <Card.Body className="p-5">
                  <h1 className="text-center mb-3 text-primary">
                    Unlock Your Revenue Potential
                  </h1>
                  <p className="text-center text-muted mb-4">
                    See how small improvements in your conversion rate can boost your sales dramatically.
                  </p>
                  <Form onSubmit={handleCalculate}>
                    <Form.Group controlId="visitors" className="mb-3">
                      <Form.Label>Number of Visitors</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="e.g. 10000"
                        value={visitors}
                        onChange={(e) => setVisitors(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="conversionRate" className="mb-3">
                      <Form.Label>Current Conversion Rate (%)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="e.g. 2.5"
                        value={conversionRate}
                        onChange={(e) => setConversionRate(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="avgOrderValue" className="mb-4">
                      <Form.Label>Average Order Value (Optional)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="e.g. 50"
                        value={avgOrderValue}
                        onChange={(e) => setAvgOrderValue(e.target.value)}
                      />
                    </Form.Group>
                    <div className="d-grid">
                      <Button variant="primary" type="submit" size="lg">
                        Calculate My Revenue
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>

              {/* Results Display */}
              {results && (
  <section aria-label="Conversion Results">
    <h2 className="text-center text-success mb-4">Your Growth Scenarios</h2>
    <Row className="gy-4"> {/* Added vertical spacing between rows */}
      {results.map((result, index) => (
        <Col xs={12} md={6} key={index}> {/* 2 per row on medium screens and larger */}
          <Card className="mb-4 shadow-sm border-0">
            <Card.Body className="text-center p-4">
              <h3 className="mb-3">{result.improvement}% Increase</h3>
              <p>
                <strong>New Conversion Rate:</strong><br />
                {result.newConversionRate}%
              </p>
              <p>
                <strong>Extra Conversions:</strong> {result.additionalConversions}
              </p>
              {result.additionalRevenue && (
                <p>
                  <strong>Extra Revenue:</strong> ${result.additionalRevenue}
                </p>
              )}
              <ProgressBar
                now={result.improvement}
                label={`${result.improvement}%`}
                variant="success"
                className="mt-3"
              />
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
    <div className="text-center mt-4">
      <Button variant="blue" size="lg" href="/contact-us">
        Start Optimizing Your Website
      </Button>
    </div>
  </section>
)}
            </Col>
          </Row>
        </Container>
      </main>
    </AppLayout>
  );
}

export default ConversionCalculator;