import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

// components
import NioField from '../../../NioField/NioField';
import NioCard from '../../../Cards/NioCard/NioCard';
import NioButton from '../../../NioButton/NioButton';
import NioToaster from '../../../NioToaster/NioToaster';
import useRouteMatch from '../../../../hooks/useRouteMatch';

export default function ContactForm() {
  const [status, setStatus] = useState(false)
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await fetch('/api/contact-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        setStatus(true);
        reset();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <>
      <NioCard className="rounded-2">
        <NioCard.Body>
          <form onSubmit={handleSubmit(onSubmit)} className="form-submit-init">
            <Row className="g-gs">
              <Col xs={12}>
                <NioField label="Website URL" htmlFor="weburl">
                  <NioField.Input id="weburl" placeholder="Enter Your Website URL" errors={errors.weburl} {...register("weburl", {
                    required: "Website URL is required"
                  })} />
                </NioField>
              </Col>
              <Col xs={12}>
                <NioField label="Email" htmlFor="email">
                  <NioField.Input type="email" id="email" placeholder="Enter Your Email" errors={errors.email} {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Invalid email address"
                    }
                  })} />
                </NioField>
              </Col>
              <Col xs={12}>
                <div className="form-group">
                <NioButton
  type="submit"
  className={useRouteMatch(["/contact-us"]) ? "btn-active-route" : "btn-default"}
  label="Book Your Free Consultation"
  style={{ backgroundColor: '#0062ff', color: 'white' }}
/>                </div>
              </Col>
            </Row>
          </form>
        </NioCard.Body>
      </NioCard>
      {status && <NioToaster variant="success text-white" messages={[{ id: 1, header: 'Success', body: 'We have successfully received your message. We will get back to you soon.' }]} />}
    </>
  )
}