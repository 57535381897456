import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';


// layout 
import AppLayout from '../../layouts/AppLayout/AppLayout';

// components
import { NioButton, NioIcon, NioSection, NioMedia, NioCard, NioSubscribeField, NioBrand } from '../../components';

function index() {
  return (
    <AppLayout title="About" rootClass="layout-11">

      {/*  Story Section Start  */}
      <NioSection className="pt-120 pt-lg-160" masks={["blur-1 left center"]}>
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="nk-section-head text-center">
              <span className="d-inline-block fs-14 text-uppercase text-primary fw-semibold mb-2" >Our Story</span>
              <h2 >The Story Behind Site OptimizR</h2>
              <p className="fs-18 mb-0" >
              At Site OptimizR, we’re a dynamic team of marketing specialists and CXL-certified conversion rate optimization experts. We elevate eCommerce businesses with hands-on experience, innovation, and proven results—driving real growth and efficiency for our clients.</p>
              
            </div>
          </Col>
        </Row>
       
      </NioSection>
      {/*  Story Section End */}

      {/*  Features Section Start  */}
      <NioSection>
        <NioSection.Content>
          <Row className="gy-5">
            <Col md={6} >
              <NioCard>
                <NioCard.Body>
                  <div className="media-group gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                    <div className="media-text">
                      <h1 className="text-primary">20+</h1>
                    </div>
                    <div className="media-text m-0">
                      <h4 className="text-capitalize">Dedicated Experts</h4>
                      <p> A compact, highly skilled team of conversion rate optimization experts and web developpers committed to your success. </p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} >
              <NioCard>
                <NioCard.Body>
                  <div className="media-group gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                    <div className="media-text">
                      <h1 className="text-success">100%</h1>
                    </div>
                    <div className="media-text m-0">
                      <h4 className="text-capitalize">Customer satisfaction</h4>
                      <p>Our commitment to excellence reflects in our impressive client satisfaction rate.</p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Features Section End  */}

     
      {/*  Values Section Start  */}
      <NioSection>
        <NioSection.Head alignX="center">
          <span className="d-inline-block fs-16 text-uppercase text-primary fw-semibold mb-2">our values</span>
          <h2>Core Values We Define</h2>
          <p className="fs-20">Innovation, Collaboration, Excellence. These core values guide us as we strive to deliver exceptional solutions and empower businesses.</p>
        </NioSection.Head>
        <NioSection.Content>
          <Row className="gy-5">
            <Col md={6} lg={4} >
              <NioCard>
                <NioCard.Body>
                  <NioMedia
                    rounded
                    size="lg"
                    className="mb-4"
                    variant="primary-soft"
                    icon="wallet"
                  />
                  <h4>Conversion-Focused</h4>
                  <p > Conversions are our currency. Our commitment lies in crafting strategies that not only attract but convert, ensuring every click moves the needle for our clients.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4} >
              <NioCard>
                <NioCard.Body>
                  <NioMedia
                    rounded
                    size="lg"
                    icon="gift"
                    className="mb-4"
                    variant="info-soft"
                  />
                  <h4>Dedication to Excellence</h4>
                  <p >Excellence is not just a value; it's our standard. We pledge to deliver superior service and CRO solutions that stand out in the digital realm, setting our clients up for unmatched success.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4} >
              <NioCard>
                <NioCard.Body>
                  <NioMedia
                    rounded
                    size="lg"
                    className="mb-4"
                    variant="warning-soft"
                    icon="growth-fill"
                  />
                  <h4>Strategic Growth</h4>
                  <p > We're not just about scaling businesses; we're about elevating them through continuous optimization and innovative tactics that guarantee long-term prosperity. </p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4} >
              <NioCard>
                <NioCard.Body>
                  <NioMedia
                    rounded
                    size="lg"
                    className="mb-4"
                    icon="users-fill"
                    variant="danger-soft"
                  />
                  <h4>Collaborative Spirit</h4>
                  <p > Our strength lies in unity. We champion a culture of teamwork, valuing the collaborative effort that fuels creative solutions and transformative results for our clients.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4} >
              <NioCard>
                <NioCard.Body>
                  <NioMedia
                    rounded
                    size="lg"
                    className="mb-4"
                    icon="bulb-fill"
                    variant="primary-soft"
                  />
                  <h4>Forward-Thinking</h4>
                  <p > We're dedicated to staying ahead of the curve, employing cutting-edge technologies and methodologies that redefine what's possible in conversion rate optimization.</p>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md={6} lg={4} >
              <NioCard>
                <NioCard.Body>
                  <NioMedia
                    rounded
                    size="lg"
                    className="mb-4"
                    icon="flag-fill"
                    variant="success-soft"
                  />
                  <h4>Accountability</h4>
                  <p >We embrace ownership. Each member of our team takes full responsibility for their contributions, ensuring we collectively drive success and exceed expectations. </p>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>

      </NioSection>
      {/*  Values Section Start  */}

      
      {/*  Brands Section Start  */}
      <NioSection>
        <NioSection.Head alignX="center">
          <span className="d-inline-block fs-16 text-uppercase text-primary fw-semibold mb-2">support From</span>
          <h2>Our Partners</h2>
          <p className="fs-20">We are proud to integrate top-tier tools like Microsoft Clarity, PostHog, and Convert.com into our operations, ensuring the best management and optimization of our clients' websites.</p>
        </NioSection.Head>
        <NioSection.Content>
         
        </NioSection.Content>
      </NioSection>
      {/*  Brands Section Start  */}

     

     

    </AppLayout >
  )
}

export default index