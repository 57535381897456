import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';
import AppLayout from '../../layouts/AppLayout/AppLayout';
import {
  NioButton,
  NioCount,
  NioMedia,
  NioSection,
  NioCard,
} from '../../components';
import FaqContent from '../../components/PageComponents/Homepages/BSSubscription/FaqContent/FaqContent';
import PricingContent from '../../components/PageComponents/Homepages/BSSubscription/PricingContent/PricingContent';
import ContactForm from '../../components/PageComponents/SectionComponents/ContactForm/ContactForm';
import { ParallaxProvider } from 'react-scroll-parallax';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import axios from 'axios';
import classNames from 'classnames';

const LandingPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const handleBlur = () => {
    if (!email.startsWith('https://')) {
      setError('Email must start with https://');
    } else {
      setError('');
    }
  };
 

  const { scrollY } = useViewportScroll();
  const backgroundY = useTransform(scrollY, [0, 500], [0, -250]);
  

  const fadeInUp = {
    initial: { opacity: 0, y: 50 },
    whileInView: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
    viewport: { once: true },
  };

  
  const features = [
    {
      icon: 'trend-up',
      variant: 'primary-alt-soft',
      title: 'Increase Profit and Revenue',
      description:
        'Boost your website’s profitability with our proven CRO strategies.',
    },
    {
      icon: 'users',
      variant: 'success-soft',
      title: 'Understand Your Customers',
      description:
        'Gain valuable insights into customer behavior for better engagement.',
    },
    {
      icon: 'tree-structure-fill',
      variant: 'warning-soft',
      title: 'A/B Testing',
      description:
        'Optimize with data-driven A/B testing to maximize your site’s success.',
    },
    {
      icon: 'edit-alt',
      variant: 'danger-soft',
      title: 'Unlock Actionable Insights',
      description:
        'Transform your data into actionable insights for smarter decisions.',
    },
    {
      icon: 'signal',
      variant: 'purple-soft',
      title: 'Visualize User Engagement',
      description:
        'Enhance UX with heat maps to understand user interactions better.',
    },
    {
      icon: 'grid',
      variant: 'purple-soft',
      title: 'Elite Tools, Proven Success',
      description:
        'Leverage industry-leading tools for unmatched CRO performance.',
    },
  ];

  const FeatureCard = ({ icon, variant, title, description }) => (
    <NioCard className="text-center bg-transparent border-0 hover-shadow transition-all duration-300">
      <NioCard.Body className="p-4">
        <NioMedia
          size="lg"
          rounded
          icon={icon}
          variant={variant}
          className="mb-4"
        />
        <div className="mb-4">
          <h4>{title}</h4>
          <p className="fs-16">{description}</p>
        </div>
      </NioCard.Body>
    </NioCard>
  );


  return (
    <div className="center-content-mobile">
    <ParallaxProvider>
      <AppLayout
        variant={12}
        title="Optimize Your Website | Free Consultation"
        description="Boost your website's conversion rates with our expert CRO strategies. Book a free consultation today!"
        rootClass="layout-11"
      >
        {/* Banner Section */}
        <section className="is-theme-bg bg-gray banner-section mt-5">
          <motion.div
            className="background-image"
            style={{
              y: backgroundY,
            }}
          />
          <Container>
            <Row className="align-items-center justify-content-between position-relative">
              <Col lg={5}>
                <motion.div
                  className="nk-section-head text-lg-start pb-5 pb-sm-7 pb-lg-0 mt-9"
                  {...fadeInUp}
                >
                  <h1 className="display-4 mb-4 text-blue time-to">
                    Time to <br className="d-none d-lg-block" />
                    <motion.span
                      className="optimize-highlight"
                      style={{ position: 'relative', display: 'inline-block' }}
                    >
                      <motion.span
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                      >
                        Optimize
                      </motion.span>
                      <motion.div
                        style={{
                          position: 'absolute',
                          bottom: '-2px',
                          left: 0,
                          width: '100%',
                          height: '2px',
                          background: 'linear-gradient(90deg, transparent, #8a2be2, transparent)',
                          transformOrigin: 'left',
                        }}
                        animate={{
                          scaleX: [0, 1, 0],
                          x: ['-100%', '0%', '100%'],
                        }}
                        transition={{
                          duration: 2,
                          repeat: Infinity,
                          ease: 'easeInOut',
                        }}
                      />
                    </motion.span>{' '}
                    <span className="d-lg-block">Your Website</span>
                  </h1>
                  {/* <motion.h6
                    className="fs-18 mb-4 text-black"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.7 }}
                    viewport={{ once: true }}
                  >
                    Leveraging our innovative blend of creative strategies and
                    meticulous data analysis, we guarantee significant
                    enhancements in your conversion rates and revenue streams.
                  </motion.h6> */}
                  <motion.h5
                    className="text-blue mb-3 mt-4 time-to"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.9 }}
                    viewport={{ once: true }}
                  >
                    Trusted by Industry Leaders
                  </motion.h5>
                  <motion.div
                    className="d-flex justify-content-start align-items-center mb-4"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1.1 }}
                    viewport={{ once: true }}
                  >
                    {['dennys.svg', 'holiday.png', 'subway.png', 'remax.png', 'jiffy.png'].map(
                      (img, index) => (
                        <div
                          key={index}
                          className="client-logo mr-5"
                          style={{ width: '80px', height: '70px', marginLeft:'10px' }}
                        >
                          <img
                            src={`/images/${img}`}
                            alt={`${img.split('.')[0]} logo`}
                            className="img-fluid"
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                          />
                        </div>
                      )
                    )}
                  </motion.div>
                </motion.div>
              </Col>

              <Col lg={6}>
                <motion.div
                  className="consultation-form-container"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
<h3 className="text-blue mb-2 mt-0 mt-lg-8 text-center">
Book Your Free Consultation Now
</h3>
                  <p className="text-center mb-4">
                    Unlock the potential of your website with our expert CRO
                    strategies!
                  </p>
                  <form 
  action="https://formspree.io/f/mgvoyegj" 
  method="POST" 
  className="w-100"
>
  {/* Name Field */}
  <div className="mb-4">
    <input
      type="text"
      name="name" 
      className="form-control form-control-lg"
      placeholder="Your Name"
      value={name}
      onChange={(e) => setName(e.target.value)}
      required
    />
  </div>

  {/* Email Field */}
  <div className="mb-4">
    <input
      type="email"
      name="email"  
      className="form-control form-control-lg"
      placeholder="Your Email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      required
    />
  </div>

  {/* Website URL Field */}
  <div className="mb-4">
    <input
      type="text"
      name="website" 
      className="form-control form-control-lg"
      placeholder="Your Website URL"
      value={url}
      onChange={(e) => setUrl(e.target.value)}
      required
    />
  </div>

  {/* Submit Button */}
  <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
    <button
      type="submit"
      className="btn text-nowrap w-100 text-white"
      style={{
        padding: '15px 25px',
        fontSize: '18px',
        backgroundColor: '#0062FF',
        border: 'none',
        cursor: 'pointer',
      }}
    >
      Book Your Free Consultation
    </button>
  </motion.div>
</form>
                  
                  <p className="mt-3 text-center text-muted">
                    No obligation, cancel anytime
                  </p>
                </motion.div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* New Banner Section (Commented Out) */}
        {/* <section className="is-theme-bg bg-gray banner-section">
          <motion.div
            className="background-image"
            style={{
              y: backgroundY,
            }}
          />
          <Container>
            <Row className="align-items-center justify-content-between position-relative">
              <Col lg={5}>
                <motion.div
                  className="nk-section-head text-lg-start pb-5 pb-sm-7 pb-lg-0 mt-9"
                  {...fadeInUp}
                >
                  <h1 className="display-4 mb-4 text-blue">
                    Time to <br className="d-none d-lg-block" />
                    <motion.span
                      className="optimize-highlight"
                      style={{ position: 'relative', display: 'inline-block' }}
                    >
                      <motion.span
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                      >
                        Optimize
                      </motion.span>
                      <motion.div
                        style={{
                          position: 'absolute',
                          bottom: '-2px',
                          left: 0,
                          width: '100%',
                          height: '2px',
                          background: 'linear-gradient(90deg, transparent, #8a2be2, transparent)',
                          transformOrigin: 'left',
                        }}
                        animate={{
                          scaleX: [0, 1, 0],
                          x: ['-100%', '0%', '100%'],
                        }}
                        transition={{
                          duration: 2,
                          repeat: Infinity,
                          ease: 'easeInOut',
                        }}
                      />
                    </motion.span>{' '}
                    <span className="d-lg-block">Your Website</span>
                  </h1>
                  <motion.h6
                    className="fs-18 mb-4 text-black"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.7 }}
                    viewport={{ once: true }}
                  >
                    Leveraging our innovative blend of creative strategies and
                    meticulous data analysis, we guarantee significant
                    enhancements in your conversion rates and revenue streams.
                  </motion.h6>
                </motion.div>
              </Col>

              <Col lg={6}>
                <motion.div
                  className="consultation-form-container"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <h3 className="text-blue mb-4 text-center">
                    Book Your Free Consultation Now
                  </h3>
                  <p className="text-center mb-4">
                    Unlock the potential of your website with our expert CRO
                    strategies!
                  </p>
                  <form onSubmit={handleSubmit} className="w-100">
                    <div className="mb-4">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        placeholder="Your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        type="url"
                        className="form-control form-control-lg"
                        placeholder="Your Website URL"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        required
                      />
                    </div>
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <NioButton
                        type="primary"
                        label="Book Your Free Consultation"
                        className="btn text-nowrap w-100 text-white"
                        style={{
                          padding: '15px 25px',
                          fontSize: '18px',
                          backgroundColor: '#8a2be2',
                        }}
                      />
                    </motion.div>
                  </form>
                  <p className="mt-3 text-center text-muted">
                    No obligation, cancel anytime
                  </p>
                  <motion.h5
                    className="text-blue mb-3 text-center mt-4"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.9 }}
                    viewport={{ once: true }}
                  >
                    Trusted by Industry Leaders
                  </motion.h5>
                  <motion.div
                    className="d-flex justify-content-center align-items-center mb-4"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 1.1 }}
                    viewport={{ once: true }}
                  >
                    {['dennys.svg', 'holiday.png', 'subway.png', 'remax.png', 'jiffy.png'].map(
                      (img, index) => (
                        <div
                          key={index}
                          className="client-logo mr-5"
                          style={{ width: '80px', height: '70px', marginLeft:'10px' }}
                        >
                          <img
                            src={`/images/${img}`}
                            alt={`${img.split('.')[0]} logo`}
                            className="img-fluid"
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                          />
                        </div>
                      )
                    )}
                  </motion.div>
                </motion.div>
              </Col>
            </Row>
          </Container>
        </section> */}

       
          {/* Features Section */}
          <NioSection className="nk-section-features py-7 py-lg-120 ta">
            <NioSection.Head alignX="center">
              <motion.div {...fadeInUp}>
                <h2>Benefits of our Conversion Rate Optimization service</h2>
                <h3 className="fs-20 mb-0 text-blue">
                  Turn your website traffic into revenue!
                </h3>
              </motion.div>
            </NioSection.Head>
            <NioSection.Content>
              <Row className="gy-5 gy-lg-7">
                {/* On mobile: 2 per row. On medium: 2. On large: 3. */}
                {features.map((feature, index) => (
                  <Col xs={6} md={6} lg={4} key={index}>
                    <motion.div
                      initial={{ opacity: 0, y: 50 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      viewport={{ once: true }}
                    >
                      <NioCard className="text-center bg-transparent border-0 hover-shadow transition-all duration-300">
                        <NioCard.Body className="p-4">
                          <NioMedia
                            size="lg"
                            rounded
                            icon={feature.icon}
                            variant={feature.variant}
                            className="mb-4"
                          />
                          <div className="mb-4">
                            {/* 
                              Use nk-feature-title and nk-feature-desc 
                              so your text clamp CSS applies 
                            */}
                            <h4 className="nk-feature-title">{feature.title}</h4>
                            {/* <p className="nk-feature-desc fs-16">
                              {feature.description}
                            </p> */}
                          </div>
                        </NioCard.Body>
                      </NioCard>
                    </motion.div>
                  </Col>
                ))}
              </Row>
            </NioSection.Content>
          </NioSection>

        {/* Statistics Section */}
        <NioSection className="py-7 py-lg-220 overflow-hidden bg-gray" masks={['shape-21']}>
          <Row className="justify-content-center">
            <Col lg={8}>
              <motion.div className="pb-5 pb-lg-7 text-center" {...fadeInUp}>
                <h2>
                  Enhance your website's performance using our tested strategies
                </h2>
                <h6 className="text-danger">
                  The minimum results most of our clients experience
                </h6>
              </motion.div>
            </Col>
          </Row>
          <NioSection.Content>
            <Row className="gy-5 gy-lg-0">
              <Col xs={6} lg={3}>
                <motion.div
                  className="text-center"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  viewport={{ once: true }}
                >
                  <NioCount
                    className="h1 text-indigo mb-3"
                    end={1.5}
                    decimals={1}
                    decimal="."
                  />
                  <span className="h3 text-indigo mb-3">X</span>
                  <h6 className="fw-normal">Conversion Rate Increase</h6>
                </motion.div>
              </Col>
              <Col xs={6} lg={3}>
                <motion.div
                  className="text-center"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  viewport={{ once: true }}
                >
                  <NioCount
                    className="h1 text-indigo mb-3"
                    end={2}
                    decimals={0}
                    decimal="."
                  />
                  <span className="h3 text-indigo mb-3">X</span>
                  <h6 className="fw-normal">Monthly Revenue Increase</h6>
                </motion.div>
              </Col>
              <Col xs={6} lg={3}>
                <motion.div
                  className="text-center"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  viewport={{ once: true }}
                >
                  <NioCount
                    className="h1 text-indigo mb-3"
                    end={20}
                    decimals={0}
                    decimal="."
                  />
                  <span className="h3 text-indigo mb-3">%</span>
                  <h6 className="fw-normal">Decrease in Bounce Rate</h6>
                </motion.div>
              </Col>
              <Col xs={6} lg={3}>
                <motion.div
                  className="text-center"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                  viewport={{ once: true }}
                >
                  <NioCount
                    className="h1 text-indigo mb-3"
                    end={50}
                    decimals={0}
                    decimal="."
                  />
                  <span className="h3 text-indigo mb-3">%</span>
                  <h6 className="fw-normal">Growth in Customer Retention</h6>
                </motion.div>
              </Col>
            </Row>
          </NioSection.Content>
        </NioSection>

        {/* Process Section
        <NioSection className="nk-how-it-work-section overflow-hidden">
          <Row className="justify-content-center">
            <Col lg={8} xl={7}>
              <motion.div
                className="nk-section-head text-center pb-7"
                {...fadeInUp}
              >
                <span className="d-inline-block fs-14 fw-bold text-uppercase text-danger mb-2">
                  OUR APPROACH
                </span>
                <h2>Discover Our Methodology</h2>
                <p className="fs-20 mb-0">
                  Witness firsthand how our strategic, data-driven approach
                  elevates website performance and drives user engagement,
                  directly boosting your revenue. Begin your transformative
                  journey now!
                </p>
                <ul className="nk-btn-group justify-content-center pt-5">
                  <li>
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <a href="#book-consultation">
                        <NioButton
                          label="Book a free consultation"
                          className="btn text-nowrap"
                          style={{ backgroundColor: '#8a2be2', color: '#fff' }}
                        />
                      </a>
                    </motion.div>
                  </li>
                </ul>
              </motion.div>
            </Col>
          </Row>
          <NioSection.Content>
            <Row className="flex-row-reverse align-items-center justify-content-between">
              <Col lg={5}>
                <motion.div
                  className="nk-video nk-video-s1 mb-5 mb-md-7 mb-lg-0"
                  initial={{ opacity: 0, scale: 0.8 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <div className="nk-mask shape-28 d-none d-md-block"></div>
                  <div className="nk-video-inner">
                    <div className="nk-video-content">
                      <div className="nk-video-img">
                        <img
                          src="images/business-digital/section-cover-1.jpg"
                          alt="Methodology Illustration"
                          className="rounded-2 w-100"
                        />
                      </div>
                    </div>
                  </div>
                </motion.div>
              </Col>
              <Col lg={6} xl={5}>
                <ul className="nk-schedule d-flex flex-column gap-5 nk-schedule-s2">
                  {[
                    {
                      step: '1',
                      title: 'Initial Free Consultation',
                      description:
                        'Begin with a foundational meeting where we assess your business and website to understand your unique goals and challenges.',
                    },
                    {
                      step: '2',
                      title: 'Strategic Optimization and Insightful Analysis',
                      description:
                        'Harness advanced analytics to unlock insights into user behavior, guiding targeted enhancements. Utilize our data-driven findings to methodically improve your website, focusing on boosting efficiency, engagement, and overall performance.',
                    },
                    {
                      step: '3',
                      title: 'Revenue Amplification',
                      description:
                        'Optimize for maximum revenue impact through strategic CRO initiatives, focusing on high-conversion strategies that enhance profitability.',
                    },
                  ].map((item, index) => (
                    <motion.li
                      key={index}
                      className="nk-schedule-item p-0"
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      viewport={{ once: true }}
                    >
                      <div className="nk-schedule-item-inner">
                        <div className="nk-schedule-symbol">
                          <NioMedia
                            size="md"
                            rounded
                            icon="check"
                            variant="danger-soft"
                          />
                        </div>
                        <div className="nk-schedule-content">
                          <span className="fs-14 fw-semibold text-uppercase mb-3">
                            Step {item.step}
                          </span>
                          <div>
                            <h3 className="mb-2">{item.title}</h3>
                            <p className="fs-16">{item.description}</p>
                          </div>
                        </div>
                      </div>
                    </motion.li>
                  ))}
                </ul>
              </Col>
            </Row>
          </NioSection.Content>
        </NioSection> */}

      

        {/* Target Audience Section */}
        {/* <NioSection className="nk-section-features py-7 bg-white py-lg-120 ta">
          <NioSection.Head alignX="center">
            <motion.div {...fadeInUp}>
              <h2>Who are we for?</h2>
              <p className="fs-20 mb-0">Empowering Your Digital Success</p>
            </motion.div>
          </NioSection.Head>
          <NioSection.Content>
            <Row className="gy-5 gy-lg-7">
              {[
                {
                  icon: 'bag',
                  variant: 'primary-alt-soft',
                  title: 'E-Commerce Platforms',
                //  description:
                //    'Boost sales with optimized user experiences that drive conversions.',
                },
                {
                  icon: 'cloud',
                  variant: 'success-soft',
                  title: 'SaaS Providers',
                //  description:
                //    'Increase subscription rates with streamlined landing pages and sign-up flows.',
                },
                {
                  icon: 'exchange',
                  variant: 'warning-soft',
                  title: 'B2B Businesses',
                //  description:
                //    'Generate more leads with enhanced CTAs and optimized lead capture strategies.',
                },
                {
                  icon: 'building',
                  variant: 'danger-soft',
                  title: 'Law and Accounting Firm',
                //  description:
                //    'Optimize client acquisition and engagement with strategically designed calls to action.',
                },
                {
                  icon: 'home',
                  variant: 'purple-soft',
                  title: 'Construction Companies',
                //  description:
                //    'Elevate project bookings with refined website interfaces and strategic CTAs.',
                },
                {
                  icon: 'book',
                  variant: 'purple-soft',
                  title: 'Online Education Platforms',
                //   description:
                //     'Drive course enrollments with user-friendly navigation and clear sign-up processes.',
                 },
              ].map((item, index) => (
                <Col md={6} lg={4} key={index}>
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    viewport={{ once: true }}
                  >
                    <FeatureCard {...item} />
                  </motion.div>
                </Col>
              ))}
            </Row>
          </NioSection.Content>
        </NioSection> */}
  <NioSection className="nk-section-features py-7 bg-white py-lg-120 ta ta-t">
  <NioSection.Head alignX="center">
    <motion.div {...fadeInUp}>
      <h2>Who are we for?</h2>
      <p className="fs-20 mb-0">Empowering Your Digital Success</p>
    </motion.div>
  </NioSection.Head>
  <NioSection.Content>
    <Row className="gy-5 gy-lg-7">
      {[
        {
          icon: 'bag',
          variant: 'primary-alt-soft',
          title: 'E-Commerce Platforms',
        },
        {
          icon: 'cloud',
          variant: 'success-soft',
          title: 'SaaS Providers',
        },
        {
          icon: 'exchange',
          variant: 'warning-soft',
          title: 'B2B Businesses',
        },
        {
          icon: 'building',
          variant: 'danger-soft',
          title: 'Law and Accounting Firm',
        },
        {
          icon: 'home',
          variant: 'purple-soft',
          title: 'Construction Companies',
        },
        {
          icon: 'book',
          variant: 'purple-soft',
          title: 'Online Education Platforms',
        },
      ].map((item, index) => (
        <Col xs={6} md={6} lg={4} key={index}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            viewport={{ once: true }}
          >
            <FeatureCard {...item} />
          </motion.div>
        </Col>
      ))}
    </Row>
  </NioSection.Content>
</NioSection>

        {/* Pricing Plans Section */}
        <NioSection id="plans" className="nk-section-pricing bg-white">
          <PricingContent />
        </NioSection>

 {/* FAQs Section */}
 <NioSection id="faq" className="nk-section-faq bg-gray">
          <Row className="justify-content-between">
            <Col xl={4}>
              <motion.div
                className="nk-section-head pb-5 pb-xl-0"
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
              >
                <h2 className='time-to'>Frequently Asked Questions</h2>
                
                <ul className="nk-btn-group pt-5">
                 
                </ul>
              </motion.div>
            </Col>
            <Col id="faq" xl={8}>
              <FaqContent />
            </Col>
          </Row>
        </NioSection>

        {/* Consultation Booking Section */}
        <NioSection id="book-consultation" className="nk-section-cta pt-7 pt-lg-120 bg-white">
          <motion.div
            className="nk-section-head text-center pb-7"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <h2 className='text-blue'>Book a Free Consultation</h2>
          </motion.div>
          <form 
  action="https://formspree.io/f/mgvoyegj" 
  method="POST" 
  className="w-100"
>
  {/* Name Field */}
  <div className="mb-4">
    <input
      type="text"
      name="name" 
      className="form-control form-control-lg"
      placeholder="Your Name"
      value={name}
      onChange={(e) => setName(e.target.value)}
      required
    />
  </div>

  {/* Email Field */}
  <div className="mb-4">
    <input
      type="email"
      name="email"  
      className="form-control form-control-lg"
      placeholder="Your Email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      required
    />
  </div>

  {/* Website URL Field */}
  <div className="mb-4">
    <input
      type="text"
      name="website" 
      className="form-control form-control-lg"
      placeholder="Your Website URL"
      value={url}
      onChange={(e) => setUrl(e.target.value)}
      required
    />
  </div>

  {/* Submit Button */}
  <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
    <button
      type="submit"
      className="btn text-nowrap w-100 text-white"
      style={{
        padding: '15px 25px',
        fontSize: '18px',
        backgroundColor: '#0062FF',
        border: 'none',
        cursor: 'pointer',
      }}
    >
      Book Your Free Consultation
    </button>
  </motion.div>
</form>
        </NioSection>
      </AppLayout>
    </ParallaxProvider>
    </div>
  );
};

export default LandingPage;