import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AppLayout from '../../../layouts/AppLayout/AppLayout';
import { NioSection } from '../../../components';

function PrivacyPolicy() {
  return (
    <AppLayout title="Privacy Policy" rootClass="layout-1">
      <NioSection className="pt-120 pt-xl-180 overflow-hidden pb-0" masks={["blur-1 left top", "blur-1 right bottom"]}>
        <Row className="justify-content-center">
          <Col lg={10}>
            <div className="pb-5 pb-lg-7">
              <h1 className="mb-4">Privacy Policy</h1>
              <p><em>Last Updated: 13 January 2024</em></p>
              <p><em>Effective On: 13 January 2024</em></p>

              <p>
                This Privacy Policy describes the policies of <strong>Site Optimizr</strong> on the
                collection, use, and disclosure of your information when you use our website
                (<a href="https://siteoptimizr.com" target="_blank" rel="noopener noreferrer">https://siteoptimizr.com</a>).
                By accessing or using the Service, you are consenting to the collection, use, and
                disclosure of your information in accordance with this Privacy Policy. If you do
                not consent, please do not access or use the Service.
              </p>

              <p>
                We may modify this Privacy Policy at any time without prior notice and will post
                the revised Privacy Policy on the Service. The revised Policy will be effective
                180 days from when the revised Policy is posted, and your continued access or use
                of the Service after such time will constitute your acceptance of the revised
                Privacy Policy. We therefore recommend that you periodically review this page.
              </p>

              <h4>Information We Collect</h4>
              <p>
                We may collect and process the following personal information about you:
              </p>
              <ul>
                <li>Name</li>
                <li>Email</li>
                <li>Company Name</li>
                <li>Payment Info</li>
              </ul>

              <h4>How We Use Your Information</h4>
              <p>
                We will use the information that we collect about you for the following purposes:
              </p>
              <ul>
                <li>Marketing/Promotional</li>
                <li>Processing payment</li>
                <li>Administration info</li>
              </ul>
              <p>
                If we want to use your information for any other purpose, we will ask for your
                consent and will use your information only upon receiving that consent, and then
                only for the purpose(s) for which consent was granted unless required otherwise by law.
              </p>

              <h4>How We Share Your Information</h4>
              <p>
                We will not transfer your personal information to any third party without seeking
                your consent, except in limited circumstances as described below:
              </p>
              <ul>
                <li>Analytics</li>
                <li>Data collection &amp; process</li>
              </ul>
              <p>
                We require such third parties to use the personal information we transfer to them
                only for the purpose for which it was transferred and not to retain it longer than
                required for fulfilling the said purpose.
              </p>
              <p>
                We may also disclose your personal information for the following: (1) to comply
                with applicable law, regulation, court order, or other legal process; (2) to
                enforce your agreements with us, including this Privacy Policy; or (3) to respond
                to claims that your use of the Service violates any third-party rights. If the
                Service or our company is merged or acquired with another company, your
                information will be one of the assets transferred to the new owner.
              </p>

              <h4>Retention of Your Information</h4>
              <p>
                We will retain your personal information with us for 90 days to 2 years after users
                terminate their accounts or for as long as we need it to fulfill the purposes for
                which it was collected as detailed in this Privacy Policy. We may need to retain
                certain information for longer periods for record-keeping/reporting in accordance
                with applicable law or for other legitimate reasons like enforcement of legal
                rights, fraud prevention, etc. Residual anonymous information and aggregate
                information, neither of which identifies you (directly or indirectly), may be
                stored indefinitely.
              </p>

              <h4>Your Rights</h4>
              <p>
                Depending on the law that applies, you may have a right to access and rectify or
                erase your personal data or receive a copy of your personal data, restrict or
                object to the active processing of your data, ask us to share (port) your personal
                information to another entity, withdraw any consent you provided to us, lodge a
                complaint with a statutory authority, and such other rights as may be relevant
                under applicable laws. To exercise these rights, you can write to us at
                <strong> sales@siteoptimizr.com</strong>. We will respond to your request in
                accordance with applicable law.
              </p>
              <p>
                You may opt out of direct marketing communications or the profiling conducted for
                marketing purposes by using the unsubscribe link provided in our newsletters. If
                you do not allow us to collect or process the required personal information or
                withdraw the consent to process the same for the required purposes, you may not be
                able to access or use the services for which your information was sought.
              </p>

              <h4>Security</h4>
              <p>
                The security of your information is important to us, and we will use reasonable
                security measures to prevent the loss, misuse, or unauthorized alteration of your
                information under our control. However, given the inherent risks, we cannot
                guarantee absolute security, and consequently, we cannot ensure or warrant the
                security of any information you transmit to us, and you do so at your own risk.
              </p>

              <h4>Third-Party Links &amp; Use Of Your Information</h4>
              <p>
                Our Service may contain links to other websites that are not operated by us. This
                Privacy Policy does not address the privacy policy and other practices of any third
                parties. We strongly advise you to review the privacy policy of every site you
                visit. We have no control over and assume no responsibility for the content,
                privacy policies, or practices of any third-party sites or services.
              </p>

              <h4>Grievance / Data Protection Officer</h4>
              <p>
                If you have any queries or concerns about the processing of your information that
                is available with us, you may email our Grievance Officer at
                <strong> sales@siteoptimizr.com</strong>. We will address your concerns in
                accordance with applicable law.
              </p>

            </div>
          </Col>
        </Row>
      </NioSection>
    </AppLayout>
  )
}

export default PrivacyPolicy;