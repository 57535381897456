import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Spinner } from 'react-bootstrap';
import AppLayout from '../../../layouts/AppLayout/AppLayout';
import { Helmet } from 'react-helmet';

function SERPPreviewTool() {
  const [pageTitle, setPageTitle] = useState('');
  const [pageUrl, setPageUrl] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [previewActive, setPreviewActive] = useState(false);

  // Trigger the preview and simulate a loading delay
  const handlePreview = (e) => {
    e.preventDefault();
    setLoading(true);
    setPreviewActive(false);
    // Simulate processing delay (1 second)
    setTimeout(() => {
      setLoading(false);
      setPreviewActive(true);
    }, 1000);
  };

  // Default values if user input is empty
  const defaultTitle = pageTitle || 'Your Page Title Here';
  const defaultUrl = pageUrl || 'https://www.example.com';
  const defaultDescription = metaDescription || 'Your meta description will appear here. This summary gives users a glimpse of your page content, similar to what appears in search results.';

  return (
    <AppLayout variant={7} title="SERP Preview Tool">
      <Helmet>
        <title>SERP Preview Tool | Optimize Your Meta Tags for SEO</title>
        <meta
          name="description"
          content="Preview how your page title, URL, and meta description appear in search results. Optimize your meta tags to boost organic traffic and improve SEO performance."
        />
        <link rel="canonical" href="https://www.siteoptimizr.com/serp-preview-tool" />
        <meta property="og:title" content="SERP Preview Tool | Optimize Your Meta Tags for SEO" />
        <meta
          property="og:description"
          content="Preview how your page title, URL, and meta description appear in search results. Optimize your meta tags to boost organic traffic and improve SEO performance."
        />
        <meta property="og:url" content="https://www.siteoptimizr.com/serp-preview-tool" />
        <meta property="og:type" content="website" />
      </Helmet>

      <main>
        <Container className="py-5 mt-9">
          <Row className="justify-content-center">
            <Col md={8}>
              {/* Input Form Card */}
              <Card className="mb-4 shadow-sm border-0">
                <Card.Body className="p-5">
                  <h1 className="text-center mb-3 text-primary">SERP Preview Tool</h1>
                  <p className="text-center text-muted mb-4">
                    Enter your page title, URL, and meta description to see how your search result might appear.
                  </p>
                  <Form onSubmit={handlePreview}>
                    <Form.Group controlId="pageTitle" className="mb-3">
                      <Form.Label>Page Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your page title"
                        value={pageTitle}
                        onChange={(e) => setPageTitle(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="pageUrl" className="mb-3">
                      <Form.Label>Page URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="e.g. https://www.example.com/page"
                        value={pageUrl}
                        onChange={(e) => setPageUrl(e.target.value)}
                      />
                      <Form.Text className="text-muted">
                        Please include the protocol (e.g., https://).
                      </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="metaDescription" className="mb-4">
                      <Form.Label>Meta Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter your meta description"
                        value={metaDescription}
                        onChange={(e) => setMetaDescription(e.target.value)}
                      />
                    </Form.Group>
                    <div className="d-grid">
                      <Button variant="primary" type="submit" size="lg">
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{' '}
                            Loading...
                          </>
                        ) : (
                          'Preview'
                        )}
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>

              {/* Preview Card */}
              {previewActive && (
                <Card className="shadow-sm border-0 mb-4">
                  <Card.Body className="p-5">
                    <h2 className="text-center text-success mb-4">Search Result Preview</h2>
                    <div
                      className="serp-preview p-3"
                      style={{
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        background: '#fff'
                      }}
                    >
                      <a
                        href={defaultUrl}
                        className="serp-title"
                        style={{
                          fontSize: '18px',
                          color: '#1a0dab',
                          textDecoration: 'none',
                          display: 'block',
                          marginBottom: '5px'
                        }}
                      >
                        {defaultTitle}
                      </a>
                      <p
                        className="serp-url"
                        style={{
                          fontSize: '14px',
                          color: '#006621',
                          marginBottom: '5px'
                        }}
                      >
                        {defaultUrl}
                      </p>
                      <p
                        className="serp-description"
                        style={{
                          fontSize: '13px',
                          color: '#545454'
                        }}
                      >
                        {defaultDescription}
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              )}

                <Card className="shadow-sm border-0">
                <Card.Body className="p-5">
                    <h2 className="text-center mb-3 text-primary">Boost Your Conversions with CRO!</h2>
                    <p className="text-center text-muted mb-4">
                    Our expert Conversion Rate Optimization (CRO) services are designed to help you turn visitors into customers.
                    Not only will you see improved conversion rates, but effective CRO also enhances user engagement and contributes
                    to better SEO performance.
                    </p>
                    <div className="d-grid">
                    <Button variant="blue" size="lg" href="/contact-us">
                        Contact Us for a Free CRO Consultation
                    </Button>
                    </div>
                </Card.Body>
                </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </AppLayout>
  );
}

export default SERPPreviewTool;