import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AppLayout from '../../../layouts/AppLayout/AppLayout';
import { NioSection } from '../../../components';

function TermsAndConditions() {
  return (
    <AppLayout title="Terms and Conditions" rootClass="layout-1">
      <NioSection className="pt-120 pt-xl-180 overflow-hidden pb-0" masks={["blur-1 left top", "blur-1 right bottom"]}>
        <Row className="justify-content-center">
          <Col lg={10}>
            <div className="pb-5 pb-lg-7">
              <h1 className="mb-4">Terms and Conditions</h1>
              <p><em>Last Updated: 13 January 2024</em></p>
              
              <p>
                These Terms and Conditions (“<strong>Terms</strong>”) govern your access to and use 
                of the Site Optimizr website (the “<strong>Website</strong>”) and any related 
                services (collectively, the “<strong>Service</strong>”). By accessing or using the 
                Service, you acknowledge that you have read, understood, and agree to be bound by 
                these Terms.
              </p>

              <h4>1. Acceptance of Terms</h4>
              <p>
                If you do not agree to any part of these Terms, you are not authorized to use the 
                Service. These Terms incorporate any additional guidelines or policies (such as a 
                Master Services Agreement) that may be posted and updated on the Website.
              </p>

              <h4>2. Authorized Use</h4>
              <p>
                You agree to use the Service only for lawful purposes and in accordance with these 
                Terms. You must be at least 18 years old to use the Service, unless otherwise 
                specified.
              </p>

              <h4>3. Intellectual Property</h4>
              <p>
                All content, features, and functionality on the Service are the exclusive property 
                of Site Optimizr or its licensors. You may not reproduce, modify, distribute, or 
                publicly display any part of the Service without explicit written permission.
              </p>

              <h4>4. Accounts and Payment</h4>
              <p>
                If the Service requires you to create an account or pay a subscription fee, you 
                agree to provide accurate information and promptly update it if changes occur. 
                Payment terms, if applicable, will be specified in a separate agreement or 
                order form.
              </p>

              <h4>5. Third-Party Links</h4>
              <p>
                The Service may contain links to third-party websites or services. Site Optimizr 
                has no control over, and assumes no responsibility for, the content or practices of 
                any third-party websites. Your dealings with such third parties are solely between 
                you and them.
              </p>

              <h4>6. Changes to Terms</h4>
              <p>
                We reserve the right to modify these Terms at any time. If we make material changes, 
                we will provide notice (e.g., posting an update on the Website). By continuing to 
                use the Service after updated Terms become effective, you agree to be bound by 
                those changes.
              </p>

              <h4>7. Termination</h4>
              <p>
                We may suspend or terminate your access to the Service at any time, with or without 
                notice, if you violate these Terms or for any other reason at our sole discretion. 
                Upon termination, sections that by their nature should survive will remain in 
                effect.
              </p>

              <h4>8. Disclaimer and Liability</h4>
              <p>
                Liability limitations, warranties, and indemnifications may be addressed in a 
                separate agreement, such as a Master Services Agreement. In the absence of such 
                agreement, the Service is provided on an "AS IS" and "AS AVAILABLE" basis without 
                warranties of any kind.
              </p>

              <h4>9. Governing Law</h4>
              <p>
                These Terms shall be governed and construed in accordance with the laws of the 
                applicable jurisdiction, without regard to conflict-of-law principles. Any disputes 
                shall be resolved exclusively in the courts of that jurisdiction.
              </p>

              <h4>10. Contact Us</h4>
              <p>
                If you have any questions, concerns, or feedback regarding these Terms, please 
                contact us at <strong>sales@siteoptimizr.com</strong>.
              </p>

            </div>
          </Col>
        </Row>
      </NioSection>
    </AppLayout>
  );
}

export default TermsAndConditions;